import { AppBar, Box, Button, IconButton, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation, useNavigate, NavLink as RouterLink } from 'react-router-dom';
import styles from './navBar.styles';

export const reconNavItems = [
  { name: 'Agenda', link: '/recon/agenda' },
  { name: 'Testimonials', link: '/recon/testimonials' },
  { name: 'Committee', link: '/recon/committee' },
  { name: 'Speakers', link: '/recon/speakers' },
  { name: 'Pricing', link: '/recon/register' },
  { name: 'FAQ', link: '/recon/faq' }
];

const NavBar2025 = ({ handleDrawerToggle }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <AppBar
      component='nav'
      elevation={0}
      sx={{
        background: 'rgba(0, 0, 0, 0.80)',
        display: 'flex',
        alignItems: 'center'
      }}
      position={'sticky'}>
      <Toolbar
        sx={{
          width: 'desktop',
          height: '60px',
          padding: '17px 100px'
        }}>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          edge='start'
          onClick={handleDrawerToggle}
          sx={{
            mr: 2,
            display: { laptop: 'none' },
            color: location.pathname.startsWith('/recon') ? 'white.main' : 'black.main'
          }}>
          <MenuIcon />
        </IconButton>
        <Box
          sx={{
            width: { mobile: '100%', laptop: '55%' },
            display: { mobile: 'flex', laptop: 'block', justifyContent: 'center' }
          }}>
          {/*  remove the styles when we add agenda and speaker in navbar*/}
          <Button disableElevation disableRipple sx={styles.logoButton}>
            <Box
              component='img'
              src='https://vivpro-site.s3.amazonaws.com/Recon/2025/reconLogo.png'
              alt='logo'
              sx={{
                maxWidth: { mobile: '300px', laptop: '100%' } // auto or the default width for larger screens
              }}
            />
          </Button>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            ml: 5,
            display: { mobile: 'none', laptop: 'flex', justifyContent: 'space-between' }
          }}>
          {(location.pathname === '/recon' || location.pathname.startsWith('/recon/')) &&
            reconNavItems.map(({ name, link }) => (
              <Button
                key={name}
                sx={{ ...styles.reconButton, ...{ textTransform: 'none', pr: '32px' } }}
                component={RouterLink}
                end
                to={link}
                disableElevation
                disableRipple>
                {name}
              </Button>
            ))}
          {(location.pathname === '/recon' || location.pathname.startsWith('/recon/')) && (
            <Button
              variant='outlined'
              sx={styles.contantUsButton}
              onClick={() => {
                navigate('/recon/contactus');
              }}>
              Contact Us
            </Button>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar2025;
